import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Map, { Amount } from '@components/common/Map';
import styled from '@emotion/styled';
import SwiperWrap from '@components/common/SwiperWrap';
import Container from '@components/common/Container';

import { dimensions, fonts, colors, breakpoints, respondFrom } from '@styles';
import mapdataPl from '@assets/json/mapdata_pl.json';
import mapdataUa from '@assets/json/mapdata_ua.json';

const mapCenter = {
  lat: 51.9194,
  lng: 19.1451,
};
const initiative = {
  value: '1 260 000 zł',
  number: 103,
};
const mapId = process.env.NETTO_HELP_UKRAINE_MAP_ID;

const swiperOptions = {
  breakpoints: {
    1: {
      spaceBetween: 20,
      slidesPerView: 1.2,
    },
    768: {
      spaceBetween: 24,
      slidesPerView: 2.5,
    },
  },
};
const sliderPositions = [0, 9, 11, 17, 20];

const MapSection = styled.div`
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-top: 90px;
      padding-bottom: 80px;
    `
  )};
`;

interface SlideProps {
  data: SlideData;
}
interface SlideData {
  title: string;
  description: string;
  value: string;
  city: string;
}
const SlideContent = styled.div`
  display: flex;
  box-sizing: border-box;
  background: ${colors.white};
  flex-direction: column;
  padding: 27px 30px 34px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 8px solid ${colors.ui.main};
  height: 100%;
  ${respondFrom(
    breakpoints.md,
    css`
      padding: 27px 60px 48px;
    `
  )}
`;

const SlideCity = styled.div`
  text-transform: uppercase;
  font-family: ${fonts.extraBold};
  font-weight: 800;
  font-size: ${dimensions.fontSize.tiny}px;
  line-height: 19px;
  letter-spacing: 0.3em;
  font-size: 12px;
  line-height: 14px;
  ${respondFrom(
    breakpoints.md,
    css`
      font-size: ${dimensions.fontSize.tiny}px;
      line-height: 19px;
    `
  )}
`;
const SlideP = styled.p`
  margin-bottom: 40px;
  flex: 1 0 auto;
  font-size: 13px;
  line-height: 17px;
  ${respondFrom(
    breakpoints.md,
    css`
      font-size: 16px;
      line-height: 21px;
    `
  )}
`;
const SlideTitle = styled.h3`
  font-size: 20px;
  line-height: 24px;

  ${respondFrom(
    breakpoints.md,
    css`
      font-size: 24px;
      line-height: 29px;
    `
  )}
`;

const Slide = ({ data }: SlideProps) => {
  const { t } = useTranslation();

  return (
    <SlideContent>
      <Amount>
        {t('home_map_initiative_amount')}: {data.value}
      </Amount>
      <SlideP>{data.description}</SlideP>
      <SlideTitle>{data.title}</SlideTitle>
      <SlideCity>{data.city}</SlideCity>
    </SlideContent>
  );
};

const swiperContainerCss = css`
  position: relative;
  margin-top: -85px;
  margin-bottom: 70px;
  overflow: visible;
  ${respondFrom(
    breakpoints.md,
    css`
      margin-top: -135px;
      margin-bottom: 30px;
    `
  )}
  .swiper {
    overflow: visible !important;
  }
`;

const HomepageMap = () => {
  // const [zoom] = React.useState(mapZoom); // changed to fitBounds
  const [center] = React.useState<google.maps.LatLngLiteral>(mapCenter);
  //https://developers.google.com/maps/documentation/javascript/using-typescript#alpha_and_beta_features

  const { t } = useTranslation();

  const mapData = t('language') === 'pl' ? mapdataPl : mapdataUa;

  const slides: SlideData[] = mapData
    .filter((row, rowIndex) => sliderPositions.indexOf(rowIndex) > -1)
    .map((row, rowIndex) => {
      return {
        city: row[2],
        title: row[5],
        description: row[3],
        value: row[4],
      };
    });

  const children = slides.map((slide) => <Slide key={slide.title} data={slide}></Slide>);
  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    mapId,
    zoomControlOptions: {
      position: 5,
    },
  } as google.maps.MapOptions;

  return (
    <MapSection>
      <Container>
        <>
          <h2>{t('home_map_initiative_title')}</h2>
          <p
            css={css`
              margin-bottom: 40px;
            `}
          >
            {t('home_map_initiative_description', {
              number: initiative.number,
              value: initiative.value,
            })}
          </p>
        </>
      </Container>
      <Map
        apiKey={`${process.env.NETTO_HELP_UKRAINE_MAP_APIKEY}`}
        style={{ flexGrow: '1', height: '100%' }}
        center={center}
        {...mapOptions}
      ></Map>
      <Container css={css(swiperContainerCss)}>
        <SwiperWrap swiperOptions={swiperOptions}>{children}</SwiperWrap>
      </Container>
    </MapSection>
  );
};

export default HomepageMap;
